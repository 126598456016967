import useJwt from '@/auth/jwt/useJwt'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getClientType = () => JSON.parse(localStorage.getItem('clientType'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = user => {
  if (user !== null && user.type === 0) return '/admin/panel'
  if (user !== null && user.type === 1) {
    if (user.registerComplete === false) {
      if (user.clientType === 0) {
        return '/registro/datos-personales'
      }
      if (user.clientType === 1) {
        return '/registro/datos-cliente'
      }
    } else {
      if (user.editEnabled === true) {
        return '/cliente/editar-perfil' // PARA CUANDO TIENE LA OPCION DE EDITAR DATOS ACTIVADO E INICIA SESION
      }
      return '/cliente/panel'
    }
  }
  return { name: 'home' }
}

export const getRouteForRegisterIncomplete = user => {
  if (user.editEnabled === true) {
    return '/cliente/editar-perfil'
  }
  if (user.clientType === 0) {
    return '/registro/datos-personales'
  }
  if (user.clientType === 1) {
    return '/registro/datos-cliente'
  }
  return '/error'
}

export const getRouteForRegisterComplete = () => '/cliente/panel'
