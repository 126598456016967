// Endpoints
export default {
  loginEndpoint: 'validate-login',
  registerEndpoint: 'register-user',
  forgotEndpoint: 'forgot-user',
  getResetEndpoint: 'reset-user',
  postResetEndpoint: 'reset-password-user',
  postResetByAdminEndpoint: 'reset-password-user-by-admin',
  setEditProfileEndpoint: 'client/edit-profile-user',
  personalDataEndpoint: 'personal-data',
  locationDataEndpoint: 'location-data',
  contactLocationDataEndpoint: 'contact-location-data',
  familyDataEndpoint: 'family-data',
  efidePartnerEndpoint: 'efidepartner-data',
  bankDataEndPoint: 'bank-data',
  clientDataEndPoint: 'client-data',
  pepDataEndPoint: 'pep-data',
  professionalDataEndPoint: 'professional-data',
  affidavitDataEndPoint: 'affidavit-data',
  finishRegisterEndPoint: 'finish-register',
  // legalRepresentativesDataEndPoint: 'legal-representatives-data',
  contactPersonEndPoint: 'contact-person',
  adminAspectsEndPoint: 'admin-aspects',
  createCreditEvaluationEndPoint: 'client/credit-evaluation',
  // shareholdingCompositionDataEndPoint: 'shareholding-composition-data',
  registerPayerEndPoint: 'client/register-payer',
  getMyPayersEndPoint: 'client/my-payers',
  getAllPayersEndPoint: 'admin/all-payers',
  getPayerEndPoint: 'admin/payer',
  getClientPayerEndPoint: 'client/payer',
  updatePayerEndPoint: 'admin/update-payer',
  getAllCreditEvaluationsEndPoint: 'admin/all-evaluations',
  getCreditEvaluationEndPoint: 'admin/creditEvaluation',
  updateCreditEvaluationEndPoint: 'admin/update-creditEvaluation',
  registerClientReceiptEndPoint: 'client/register-receipt',
  getAllFundingRequestsEndPoint: 'admin/all-requests',
  mainBankAccountsEndPoint: 'main-bank-accounts',
  getMyReceiptsToSendEndPoint: 'client/my-receipts-to-send',
  sendMyReceiptsToSendEndPoint: 'client/send-my-receipts-to-send',
  getMySentReceiptsEndPoint: 'client/my-sent-receipts',
  uploadXMLToGetDataEndPoint: 'client/upload-xml-to-get-data',
  getReceiptDetailDataEndPoint: 'admin/get-receipt-detail',
  getAllReciptsEndPoint: 'admin/all-receipts',
  getClientsEndPoint: 'admin/users-list',
  sendMasiveEmailEndPoint: 'admin/send-emails',
  sendEfideDataEndPoint: 'admin/send-efideData',
  postRucByAdminEndPoint: 'admin/post-ruc-user-by-admin',
  updateReceiptEndPoint: 'admin/update-receipt',
  registerServiceEndPoint: 'admin/register-service',
  getServicesEndPoint: 'admin/get-services-list',
  getServiceDetailsEndPoint: 'admin/get-service-details',
  updateServiceEndPoint: 'admin/update-service',
  getServiceUsersEndPoint: 'admin/get-service-users-list',
  getServicesClientEndPoint: 'client/get-services-list',
  getServiceDetailsClientEndPoint: 'client/get-service-details',
  postServiceClientEndPoint: 'client/post-service',
  getClientReceiptDetailEndPoint: 'client/get-receipt-detail',
  postClientReceiptUpdateEndPoint: 'client/update-receipt',
  postEditProfileClientEndPoint: 'client/edit-profile',
  deleteReceiptEndPoint: 'client/delete-receipt',
  documentsDataEndPoint: 'documents-data',
  searchRucByEfideEndPoint: 'client/search-payer-efide',
  registerPayerByEfideEndPoint: 'client/register-payer-efide',
  getClientLineEndpoint: 'client/get-client-line',
  requestIncreaseClientLine: 'client/request-increase-client-line',
  requestIncreasePayerLine: 'client/request-increase-payer-line',
  getCurrentReceiptsPortfolioEndpoint: 'client/get-current-receipts-portfolio',
  getOutstandingGuaranteeFundsEndpoint: 'client/get-outstanding-guarantee-funds',
  getListVariablesEndpoint: 'list-variables',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
}
