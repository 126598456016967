export default [
  {
    path: '/cliente/panel',
    name: 'Client-panel',
    component: () => import('@/views/client/Client-panel.vue'),
    meta: {
      pageTitle: 'Menú Inicio',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/lista-pagadores',
    name: 'Client-payers-list',
    component: () => import('@/views/client/Client-payers-list.vue'),
    meta: {
      pageTitle: 'Lista de pagadores',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/mi-linea',
    name: 'Client-line-credit',
    component: () => import('@/views/client/Client-line-credit.vue'),
    meta: {
      pageTitle: 'Mi línea',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/registar-pagador',
    name: 'Client-register-payer',
    component: () => import('@/views/client/Client-register-payer-v2.vue'),
    meta: {
      pageTitle: 'Consultar y registrar a mis clientes',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/solicitud-ampliacion-crediticia/:id',
    name: 'Client-credit-evaluation',
    component: () => import('@/views/client/Credit-evaluation.vue'),
    meta: {
      pageTitle: 'Solicitud de ampliación de línea de crédito',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/registrar-comprobante',
    name: 'Client-register-receipt',
    component: () => import('@/views/client/Client-register-receipt-v2.vue'),
    meta: {
      pageTitle: 'Registrar mi solicitud',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/lista-solicitudes-por-enviar',
    name: 'Receipts-to-send-list',
    component: () => import('@/views/client/ReceiptsToSend-list.vue'),
    meta: {
      pageTitle: 'Confirmación de solicitudes',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/simulador-solicitudes',
    name: 'Bills-simulator',
    component: () => import('@/views/client/Bills-simulator.vue'),
    meta: {
      pageTitle: 'Simulador de solicitudes por enviar',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/lista-solicitudes-enviadas',
    name: 'Sent-receipts-list',
    component: () => import('@/views/client/SentReceipts-list.vue'),
    meta: {
      pageTitle: 'Solicitudes enviadas',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  /* {
    path: '/cliente/lista-servicios',
    name: 'Client-services-list',
    component: () => import('@/views/client/Client-services-list.vue'),
    meta: {
      pageTitle: 'Lista de servicios',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/detalle-servicio/:id',
    name: 'Client-service-detail',
    component: () => import('@/views/client/Client-service-detail.vue'),
    meta: {
      pageTitle: 'Detalle de servicio',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  }, */
  {
    path: '/cliente/editar-comprobante/:id',
    name: 'Client-edit-receipt',
    component: () => import('@/views/client/Client-edit-receipt.vue'),
    meta: {
      pageTitle: 'Edita tu comprobante',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/editar-perfil',
    name: 'Client-edit-profile',
    component: () => import('@/views/client/Client-edit-profile.vue'),
    meta: {
      pageTitle: 'Actualiza tus datos',
      redirectIfRegisterIncomplete: true,
      isClient: true,
    },
  },
  {
    path: '/solicitud-evaluacion',
    name: 'Evaluation-request',
    component: () => import('@/views/Home-Request.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/cliente/historial-cartera',
    name: 'Client-current-receipts-portfolio',
    component: () => import('@/views/client/Client-current-receipts-portfolio.vue'),
    meta: {
      pageTitle: 'Mis documentos negociados',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
  {
    path: '/cliente/fondos-garantia-pendientes',
    name: 'Client-outstanding-guarantee-funds',
    component: () => import('@/views/client/Client-outstanding-guarantee-funds.vue'),
    meta: {
      pageTitle: 'Mis fondos de garantía',
      redirectIfRegisterIncomplete: true,
      isClient: true,
      isEditEnabled: true,
    },
  },
]
