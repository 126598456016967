import Vue from 'vue'
import VueRouter from 'vue-router'

import {
  isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getRouteForRegisterComplete, getRouteForRegisterIncomplete,
} from '@/auth/utils'
import access from './routes/access'
import admin from './routes/admin'
import client from './routes/client'
import register from './routes/register'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  /* scrollBehavior() {
    return { x: 0, y: 0 }
  }, */
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'home' } },
    ...admin,
    ...client,
    ...register,
    ...access,
    {
      path: '*',
      redirect: 'error',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData || null))
  }
  if (to.meta.isAdmin) {
    const userData = getUserData()
    if (userData !== null && userData.type === 0) return next()
    if (userData === null || userData.type !== 0) return next('/inicio')
  }
  if (to.meta.isClient) {
    const userData = getUserData()
    if (userData !== null && userData.type === 1) {
      if (to.meta.redirectIfRegisterIncomplete && userData.registerComplete === false) {
        return next(getRouteForRegisterIncomplete(userData))
      }
      if (to.meta.redirectIfRegisterComplete && userData.registerComplete === true) {
        if (userData.editEnabled === true) {
          return next('/cliente/editar-perfil') // PARA CUANDO TIENE LA OPCION DE EDITAR DATOS ACTIVADO Y QUIERE IR A LAS OTRAS OPCIONES DEL PANEL
        }
        return next(getRouteForRegisterComplete())
      }
    }
    if (userData === null || userData.type !== 1) return next('/inicio')
  }
  if (to.meta.isEditEnabled) {
    const userData = getUserData()
    if (userData !== null && userData.type === 1 && userData.registerComplete === true && userData.editEnabled === true) return next('/cliente/editar-perfil') // PARA CUANDO TIENE LA OPCION DE EDITAR DATOS ACTIVADO
  }
  if (to.meta.redirectIfRegisterIncomplete) {
    const userData = getUserData()
    if (userData !== null && userData.type === 1 && userData.registerComplete === false) return next(getRouteForRegisterIncomplete(userData))
    if (userData === null) return next('/inicio')
  }
  if (to.meta.redirectIfRegisterComplete) {
    const userData = getUserData()
    if (userData !== null && userData.type === 1 && userData.editEnabled === true) return next('/cliente/editar-perfil') // PARA CUANDO QUIERE REGRESAR A LOS FORMULARIOS Y TIENE LA OPCION DE EDITAR DATOS ACTIVADO
    if (userData !== null && userData.type === 1 && userData.registerComplete === true) {
      if (userData.canEdit !== true) {
        return next(getRouteForRegisterComplete())
      }
    }
    if (userData === null) return next('/inicio')
  }

  return next()
})
router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
