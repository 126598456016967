import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For identify stage and set any kind of api
  // baseURL = process.env.NODE_ENV === 'development' ? 'http://104.236.218.113:8081/' : 'https://api.efidefactoring.pe/'
  // baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8081/' : 'https://api.efidefactoring.pe/'

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    switch (process.env.NODE_ENV) {
      case 'development':
        // this.baseURL = 'http://104.236.218.113:8091/'
        this.baseURL = 'http://localhost:8081/'
        break
      case 'production':
        this.baseURL = 'https://api.efidefactoring.pe/'
        break
      default:
        this.baseURL = 'http://localhost:8081/'
        break
    }

    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.registerEndpoint, ...args)
  }

  forgot(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.forgotEndpoint, ...args)
  }

  getReset(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getResetEndpoint, ...args)
  }

  postReset(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.postResetEndpoint, ...args)
  }

  postResetByAdmin(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.postResetByAdminEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  setEditProfile(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.setEditProfileEndpoint, ...args)
  }

  personalData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.personalDataEndpoint, ...args)
  }

  locationData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.locationDataEndpoint, ...args)
  }

  contactLocationData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.contactLocationDataEndpoint, ...args)
  }

  familyData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.familyDataEndpoint, ...args)
  }

  efidePartnerData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.efidePartnerEndpoint, ...args)
  }

  bankData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.bankDataEndPoint, ...args)
  }

  clientData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.clientDataEndPoint, ...args)
  }

  professionalData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.professionalDataEndPoint, ...args)
  }

  pepData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.pepDataEndPoint, ...args)
  }

  affidavitData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.affidavitDataEndPoint, ...args)
  }

  finishRegister(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.finishRegisterEndPoint, ...args)
  }

  /* legalRepresentativesData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.legalRepresentativesDataEndPoint, ...args)
  } */

  contactPerson(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.contactPersonEndPoint, ...args)
  }

  adminAspects(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.adminAspectsEndPoint, ...args)
  }

  createCreditEvaluation(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.createCreditEvaluationEndPoint, ...args)
  }

  /* shareholdingCompositionData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.shareholdingCompositionDataEndPoint, ...args)
  } */

  registerPayer(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.registerPayerEndPoint, ...args)
  }

  getMyPayers(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getMyPayersEndPoint, ...args)
  }

  getAllPayers(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getAllPayersEndPoint, ...args)
  }

  getClientPayer(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getClientPayerEndPoint, ...args)
  }

  getPayer(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getPayerEndPoint, ...args)
  }

  updatePayer(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.updatePayerEndPoint, ...args)
  }

  mainBankAccountsData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.mainBankAccountsEndPoint, ...args)
  }

  getAllCreditEvaluations(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getAllCreditEvaluationsEndPoint, ...args)
  }

  updateCreditEvaluation(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.updateCreditEvaluationEndPoint, ...args)
  }

  getCreditEvaluation(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getCreditEvaluationEndPoint, ...args)
  }

  registerClientReceipt(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.registerClientReceiptEndPoint, ...args)
  }

  getMyReceiptsToSend(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getMyReceiptsToSendEndPoint, ...args)
  }

  getAllFundingRequests(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getAllFundingRequestsEndPoint, ...args)
  }

  sendMyReceiptsToSend(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.sendMyReceiptsToSendEndPoint, ...args)
  }

  uploadXMLToGetData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.uploadXMLToGetDataEndPoint, ...args)
  }

  getMySentReceipts(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getMySentReceiptsEndPoint, ...args)
  }

  getReceiptDetail(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getReceiptDetailDataEndPoint, ...args)
  }

  getAllRecipts(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getAllReciptsEndPoint, ...args)
  }

  getClients(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getClientsEndPoint, ...args)
  }

  sendMasiveEmail(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.sendMasiveEmailEndPoint, ...args)
  }

  updateReceipt(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.updateReceiptEndPoint, ...args)
  }

  registerService(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.registerServiceEndPoint, ...args)
  }

  getServicesList(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getServicesEndPoint, ...args)
  }

  getServiceDetails(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getServiceDetailsEndPoint, ...args)
  }

  updateService(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.updateServiceEndPoint, ...args)
  }

  getServiceUsersList(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getServiceUsersEndPoint, ...args)
  }

  getServicesClientList(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getServicesClientEndPoint, ...args)
  }

  getServiceDetailsClient(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getServiceDetailsClientEndPoint, ...args)
  }

  postServiceClient(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.postServiceClientEndPoint, ...args)
  }

  getReceiptDetailClient(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.getClientReceiptDetailEndPoint, ...args)
  }

  postReceiptUpdateClient(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.postClientReceiptUpdateEndPoint, ...args)
  }

  postEditProfileClient(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.postEditProfileClientEndPoint, ...args)
  }

  deleteReceipt(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.deleteReceiptEndPoint, ...args)
  }

  preEvaluationEfide(...args) {
    return this.axiosIns.post('https://online.efide.pe/Landing/ApiEF/ProspectoEvaluacion', ...args)
  }

  postReturnRequest(...args) {
    return this.axiosIns.post('https://online.efide.pe/AdelantoCXC/Api/DevolucionCxP', ...args)
  }

  documentsData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.documentsDataEndPoint, ...args)
  }

  sendEfideData(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.sendEfideDataEndPoint, ...args)
  }

  postRucByAdmin(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.postRucByAdminEndPoint, ...args)
  }

  searchRucByEfide(params) {
    return this.axiosIns.get(this.baseURL + this.jwtConfig.searchRucByEfideEndPoint, { params })
  }

  registerPayerByEfide(...args) {
    return this.axiosIns.post(this.baseURL + this.jwtConfig.registerPayerByEfideEndPoint, ...args)
  }

  getClientLine(params) {
    return this.axiosIns.get(this.baseURL + this.jwtConfig.getClientLineEndpoint, { params })
  }

  requestIncreaseClientLine(params) {
    return this.axiosIns.get(this.baseURL + this.jwtConfig.requestIncreaseClientLine, { params })
  }

  requestIncreasePayerLine(params) {
    return this.axiosIns.get(this.baseURL + this.jwtConfig.requestIncreasePayerLine, { params })
  }

  getCurrentReceiptsPortfolio(params) {
    return this.axiosIns.get(this.baseURL + this.jwtConfig.getCurrentReceiptsPortfolioEndpoint, { params })
  }

  getOutstandingGuaranteeFunds(params) {
    return this.axiosIns.get(this.baseURL + this.jwtConfig.getOutstandingGuaranteeFundsEndpoint, { params })
  }

  getVariables(params) {
    return this.axiosIns.get(this.baseURL + this.jwtConfig.getListVariablesEndpoint, { params })
  }
}
