export default [
  {
    path: '/registro/datos-personales',
    name: 'Personal-data',
    component: () => import('@/views/register/naturalPerson/Personal-data.vue'),
    meta: {
      pageTitle: 'Registro - Datos Personales',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/datos-contacto-ubicacion',
    name: 'Contact-data',
    component: () => import('@/views/register/naturalPerson/Location-data.vue'),
    meta: {
      pageTitle: 'Registro - Datos de contacto y ubicación',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/datos-ubicacion',
    name: 'Location-data',
    component: () => import('@/views/register/legalPerson/Location-data.vue'),
    meta: {
      pageTitle: 'Registro - Datos de ubicación',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/aspectos-administracion',
    name: 'Administration-aspects',
    component: () => import('@/views/register/legalPerson/Administration-aspects.vue'),
    meta: {
      pageTitle: 'Registro - Aspectos de administración',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/datos-personas-politicamente-expuestas',
    name: 'PEP-data',
    component: () => import('@/views/register/naturalPerson/PEP-data.vue'),
    meta: {
      pageTitle: 'Registro - Personas politicamente expuestas',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/datos-familiares',
    name: 'Family-data',
    component: () => import('@/views/register/naturalPerson/Family-data.vue'),
    meta: {
      pageTitle: 'Registro - Datos familiares',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/datos-socio-efide',
    name: 'EfidePartner-data',
    component: () => import('@/views/register/naturalPerson/EfidePartner-data.vue'),
    meta: {
      pageTitle: 'Registro - Referencias',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/datos-bancarios',
    name: 'Bank-data',
    component: () => import('@/views/register/naturalPerson/Bank-data.vue'),
    meta: {
      pageTitle: 'Registro - Cuentas Bancarias',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/datos-cliente',
    name: 'Client-data',
    component: () => import('@/views/register/legalPerson/Client-data.vue'),
    meta: {
      pageTitle: 'Registro - Datos Cliente',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/datos-profesionales',
    name: 'Professional-data',
    component: () => import('@/views/register/naturalPerson/Professional-data.vue'),
    meta: {
      pageTitle: 'Registro - Datos Profesionales',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/declaracion-jurada',
    name: 'Affidavit-data',
    component: () => import('@/views/register/naturalPerson/Affidavit-data.vue'),
    meta: {
      pageTitle: 'Registro - Declaración Jurada (LEER DETENIDAMENTE, ES MUY IMPORTANTE)',
      redirectIfRegisterComplete: true,
    },
  },
  /* {
    path: '/registro/representantes-legales',
    name: 'LegalRepresentatives-data',
    component: () => import('@/views/register/legalPerson/LegalRepresentatives-data.vue'),
    meta: {
      pageTitle: 'Registro - Representantes Legales',
      redirectIfRegisterComplete: true,
    },
  }, */
  {
    path: '/registro/persona-contacto',
    name: 'Contact-person',
    component: () => import('@/views/register/legalPerson/Contact-person.vue'),
    meta: {
      pageTitle: 'Registro - Persona de contacto',
      redirectIfRegisterComplete: true,
    },
  },
  /* {
    path: '/registro/composicion-accionaria',
    name: 'ShareholdingComposition-data',
    component: () => import('@/views/register/legalPerson/ShareholdingComposition-data.vue'),
    meta: {
      pageTitle: 'Registro - Composición accionaria',
      redirectIfRegisterComplete: true,
    },
  }, */
  {
    path: '/registro/cuentas-bancarias-vigentes',
    name: 'BankAccounts-data',
    component: () => import('@/views/register/legalPerson/Bank-data.vue'),
    meta: {
      pageTitle: 'Registro - Cuentas Bancarias',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/legal-declaracion-jurada',
    name: 'Affidavit-legal-data',
    component: () => import('@/views/register/legalPerson/Affidavit-data.vue'),
    meta: {
      pageTitle: 'Registro - Declaración jurada',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/cuentas-bancarias-titulares',
    name: 'MainBankAccounts-data',
    component: () => import('@/views/register/naturalPerson/MainBankAccounts-data.vue'),
    meta: {
      pageTitle: 'Registro - Medios de pago',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/legal-cuentas-bancarias-titulares',
    name: 'MainBankAccounts-legal-data',
    component: () => import('@/views/register/legalPerson/MainBankAccounts-data.vue'),
    meta: {
      pageTitle: 'Registro - Medios de pago',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/legal-carga-documentacion',
    name: 'Documents-legal-data',
    component: () => import('@/views/register/legalPerson/Documents-data.vue'),
    meta: {
      pageTitle: 'Registro - Carga de documentación',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/registro/carga-documentacion',
    name: 'Documents-data',
    component: () => import('@/views/register/naturalPerson/Documents-data.vue'),
    meta: {
      pageTitle: 'Registro - Carga de documentación',
      redirectIfRegisterComplete: true,
    },
  },
  {
    path: '/ubigeo',
    name: 'ubigee-data',
    component: () => import('@/views/register/Ubigee.vue'),
    meta: {
      layout: 'full',
    },
  },
]
