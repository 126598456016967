import Vue from 'vue'
import Vuex from 'vuex'

import VuexPersistence from 'vuex-persist'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import elementVerticalMenu from './elements-vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    elementVerticalMenu,
  },
  strict: process.env.DEV,
  plugins: [new VuexPersistence().plugin],
})
