export default [
  {
    path: '/error',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/ingresar',
    name: 'auth-login',
    component: () => import('@/views/access/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/registrarse',
    name: 'auth-register-v1',
    component: () => import('@/views/access/Register-v1.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/recuperar',
    name: 'auth-forgot-password',
    component: () => import('@/views/access/ForgotPassword-v1.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reiniciar/:id',
    name: 'ResetPassword',
    component: () => import('@/views/access/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/inicio',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'full',
    },
  },
  // {
  //   path: '/pages/miscellaneous/coming-soon',
  //   name: 'misc-coming-soon',
  //   component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/miscellaneous/not-authorized',
  //   name: 'misc-not-authorized',
  //   component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //   },
  // },
  // {
  //   path: '/pages/miscellaneous/under-maintenance',
  //   name: 'misc-under-maintenance',
  //   component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  // {
  //   path: '/pages/miscellaneous/error',
  //   name: 'misc-error',
  //   component: () => import('@/views/pages/miscellaneous/Error.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
]
