import { naturalNavs, legalNavs, clientNavs } from '@/navigation/vertical'

export default {
  namespaced: true,
  state: {
    formSteps: [],
  },
  getters: {
    getFormSteps: state => state.formSteps,
  },
  mutations: {
    setSteps(state, steps) {
      state.formSteps = steps
    },
    cleanSteps(state) {
      state.formSteps = []
    },
    stepStatus(state, { index, icon }) {
      state.formSteps[index].icon = icon
    },
  },
  actions: {
    async getData({ commit }) {
      const clientType = JSON.parse(localStorage.getItem('clientType'))
      const userData = JSON.parse(localStorage.getItem('userData'))
      const typeUser = JSON.parse(localStorage.getItem('type'))

      if (userData.registerComplete === false) {
        if (typeUser === 1) {
          switch (clientType) {
            case 0:
              if (userData.personalData) {
                naturalNavs[1].icon = userData.personalData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else naturalNavs[1].icon = 'CircleIcon'
              if (userData.contactLocationData) {
                naturalNavs[2].icon = userData.contactLocationData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else naturalNavs[2].icon = 'CircleIcon'
              if (userData.professionalData) {
                naturalNavs[3].icon = userData.professionalData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else naturalNavs[3].icon = 'CircleIcon'
              if (userData.pepData) {
                naturalNavs[4].icon = userData.pepData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else naturalNavs[4].icon = 'CircleIcon'
              if (userData.familyData) {
                naturalNavs[5].icon = userData.familyData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else naturalNavs[5].icon = 'CircleIcon'
              if (userData.efidePartnerData) {
                naturalNavs[6].icon = userData.efidePartnerData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else naturalNavs[6].icon = 'CircleIcon'
              if (userData.bankData) {
                naturalNavs[7].icon = userData.bankData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else naturalNavs[7].icon = 'CircleIcon'
              if (userData.mainBankAccountsData) {
                naturalNavs[8].icon = userData.mainBankAccountsData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else naturalNavs[8].icon = 'CircleIcon'
              if (userData.documentsData) {
                naturalNavs[9].icon = userData.documentsData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else naturalNavs[9].icon = 'CircleIcon'
              if (userData.affidavitData) {
                naturalNavs[10].icon = userData.affidavitData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else naturalNavs[10].icon = 'CircleIcon'
              commit('setSteps', naturalNavs)
              break
            case 1:
              if (userData.clientData) {
                legalNavs[1].icon = userData.clientData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else legalNavs[1].icon = 'CircleIcon'
              if (userData.locationData) {
                legalNavs[2].icon = userData.locationData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else legalNavs[2].icon = 'CircleIcon'
              if (userData.adminAspects) {
                legalNavs[3].icon = userData.adminAspects.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else legalNavs[3].icon = 'CircleIcon'
              if (userData.contactPerson) {
                legalNavs[4].icon = userData.contactPerson.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else legalNavs[4].icon = 'CircleIcon'
              /* if (userData.legalRepresentativesData) {
                legalNavs[5].icon = userData.legalRepresentativesData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else legalNavs[5].icon = 'CircleIcon' */
              /* if (userData.shareholdingCompositionData) {
                legalNavs[6].icon = userData.shareholdingCompositionData.completed
                  ? 'CheckCircleIcon'
                  : 'MinusCircleIcon'
              } else legalNavs[6].icon = 'CircleIcon' */
              if (userData.mainBankAccountsData) {
                legalNavs[5].icon = userData.mainBankAccountsData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else legalNavs[5].icon = 'CircleIcon'
              if (userData.bankData) {
                legalNavs[6].icon = userData.bankData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else legalNavs[6].icon = 'CircleIcon'
              if (userData.documentsData) {
                legalNavs[7].icon = userData.documentsData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else legalNavs[7].icon = 'CircleIcon'
              if (userData.affidavitData) {
                legalNavs[8].icon = userData.affidavitData.completed ? 'CheckCircleIcon' : 'MinusCircleIcon'
              } else legalNavs[8].icon = 'CircleIcon'
              commit('setSteps', legalNavs)
              break
            default:
              commit('setSteps', [
                {
                  header: 'Registro de datos',
                },
              ])
              break
          }
        }
      } else if (typeUser === 1) {
        commit('setSteps', clientNavs)
      } else {
        commit('setSteps', [])
      }
    },
    setStatusStep({ commit, state }, { step, status }) {
      const index = state.formSteps.findIndex(formStep => formStep.route && formStep.route === step)
      if (index !== -1) {
        const icon = status === true ? 'CheckCircleIcon' : 'MinusCircleIcon'
        commit('stepStatus', { index, icon })
      }
    },
  },
}
