export default [
  {
    path: '/admin/panel',
    name: 'Admin-panel',
    component: () => import('@/views/admin/Admin-panel.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Panel-administrativo',
    },
  },
  {
    path: '/admin/lista-pagadores',
    name: 'Admin-payers-list',
    component: () => import('@/views/admin/Admin-payers-list.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Lista de pagadores',
    },
  },
  {
    path: '/admin/detalle-pagador/:id',
    name: 'Admin-payer-detail',
    component: () => import('@/views/admin/Payer-detail.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Detalle de pagador',
    },
  },
  {
    path: '/admin/lista-ampliacion-crediticia',
    name: 'Admin-creditEvalutaion-list',
    component: () => import('@/views/admin/Admin-creditEvalutaion-list.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Lista de solicitudes ampliación crediticia',
    },
  },
  {
    path: '/admin/detalle-ampliacion-crediticia/:id',
    name: 'Admin-creditEvaluation-detail',
    component: () => import('@/views/admin/CreditEvaluation-detail.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Detalle de ampliación crediticia',
    },
  },
  {
    path: '/admin/lista-solicitud-financiamiento',
    name: 'Admin-fundingRequests-list',
    component: () => import('@/views/admin/Admin-fundingRequests-list.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Lista de solicitudes de financiamiento',
    },
  },
  {
    path: '/admin/detalle-comprobante/:id',
    name: 'Admin-receipt-detail',
    component: () => import('@/views/admin/Admin-receipt-detail.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Detalle de comprobante',
    },
  },
  {
    path: '/admin/lista-comprobantes-pago/:id',
    name: 'Admin-receipts-list',
    component: () => import('@/views/admin/Admin-receipts-list.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Lista de comprobantes de pago',
    },
  },
  {
    path: '/admin/lista-clientes',
    name: 'Admin-client-list',
    component: () => import('@/views/admin/Admin-client-list.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Lista de clientes',
    },
  },
  /* {
    path: '/admin/registrar-servicio',
    name: 'Admin-register-service',
    component: () => import('@/views/admin/Admin-register-service.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Registrar servicio',
    },
  },
  {
    path: '/admin/lista-servicios',
    name: 'Admin-service-list',
    component: () => import('@/views/admin/Admin-service-list.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Lista de servicios',
    },
  },
  {
    path: '/admin/editar-servicio/:id',
    name: 'Admin-update-service',
    component: () => import('@/views/admin/Admin-update-service.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Editar servicio',
    },
  },
  {
    path: '/admin/inscritos-servicio/:id',
    name: 'Admin-service-users-list',
    component: () => import('@/views/admin/Admin-service-users-list.vue'),
    meta: {
      isAdmin: true,
      pageTitle: 'Lista de inscritos',
    },
  }, */
]
